export type MetaInfoProps = {
  readonly title?: string;
  readonly description?: string;
};

export type Route = {
  readonly path: string;
  readonly exact?: boolean;
  readonly displayName: string;
  readonly metaInfo: MetaInfoProps;
};

export const RoutesConfig = Object.freeze<Record<string, Route>>({
  Home: {
    path: "/",
    exact: true,
    displayName: "Home",
    metaInfo: {
      title: "Osteopathie und Physiotherapie Karlsruhe - Praxis Heine und Team",
      description:
        "Osteopathie und Physiotherapie Karlsruhe - Praxis Heine und Team",
    },
  }, 
  Impressum:  {
    path: "/impressum",
    exact: true,
    displayName: "Impressum",
    metaInfo: {
      title: "Impressum | Osteopathie Karlsruhe - Praxis Heine und Team",
      description: "Impressum | Osteopathie Karlsruhe - Praxis Heine und Team",
    },
  },
  Datenschutz: {
    path: "/datenschutz",
    exact: true,
    displayName: "Datenschutz",
    metaInfo: {
      title: "Datenschutz | Osteopathie Karlsruhe - Praxis Heine und Team",
      description:
        "Datenschutz | Osteopathie Karlsruhe - Praxis Heine und Team",
    },
  },
});
