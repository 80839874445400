import React from "react";
import { Box, Flex, Image, Text, useStyleConfig } from "@chakra-ui/react";
import { useLocation, NavLink } from "react-router-dom";
import { ScrollLink } from "../components/ScrollLink";
import { SectionWrapper } from "../components/SectionWrapper";
import { PageType } from "../config/theme";
import logoOsteo from "../images/logo-osteo.png";

type Props = {
  pageType: PageType;
};

export const NavBar: React.FC<Props> = ({ pageType }: Props) => {
  const themeStyles = useStyleConfig("NavBar", {});
  const location = useLocation();
  const isHomePage =
    !location.pathname.includes("datenschutz") &&
    !location.pathname.includes("impressum");

  const componentStyles = {
    position: "fixed",
    zIndex: "2",
    padding: "0.5rem 0",
    // minHeight: "4.25rem",
    textAlign: "center",
    display: ["none", "block", "block", "block"],
    opacity: "0.85",
  };
  const styles = { ...themeStyles, ...componentStyles };
  const getLogo = () => {
    return logoOsteo;
  };
  return (
    <SectionWrapper sectionName="NavBar" pageType={pageType} styles={styles}>
      <Box w="100%">
        <Flex justifyContent="space-around" alignItems="center">
          {isHomePage && (
            <>
              <ScrollLink linkText="Welcome" linkData="welcome">
                <Text
                  css={{
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <Image
                    borderRadius="full"
                    mt="1rem"
                    w="auto"
                    h="5rem"
                    src={getLogo()}
                    bg="#ffffff"
                    alt={"Praxis für Osteopathie Heine"}
                  />
                </Text>
              </ScrollLink>
              <ScrollLink linkText="Osteopathie" linkData="osteopathie" />
              <ScrollLink
                linkText="Kinderosteopathie"
                linkData="kinderOsteopathie"
              />
              <ScrollLink linkText="Über mich" linkData="team" />
              <ScrollLink linkText="Kontakt" linkData="kontakt" />
            </>
          )}

          {!isHomePage && (
            <NavLink to="/">
              <Text
                css={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Image
                  borderRadius="full"
                  mt="1rem"
                  w="auto"
                  h="5rem"
                  src={getLogo()}
                  bg="#ffffff"
                  alt={"Praxis für Osteopathie Heine"}
                />
              </Text>
            </NavLink>
          )}
        </Flex>
      </Box>
    </SectionWrapper>
  );
};
