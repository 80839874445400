import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";

import { Avatar, Box, Center, Flex } from "@chakra-ui/react";

type PersonProps = {
  imgSrc?: string;
  children: React.ReactNode;
};

export const Person: React.FC<PersonProps> = ({
  imgSrc,
  children,
}: PersonProps) => {
  const [res, setRes] = useState<string>("");
  const [
    isLargerThan320px,
    isLargerThan768px,
    isLargerThan960px,
    isLargerThan1200px,
    isLargerThan1600px,
    isLargerThan1920px,
  ] = useMediaQuery([
    "(min-width: 320px)",
    "(min-width: 768px)",
    "(min-width: 960px)",
    "(min-width: 1200px)",
    "(min-width: 1600px)",
    "(min-width: 1920px)",
  ]);

  useEffect(() => {
    setRes(getResolution());
  });

  function getResolution() {
    if (isLargerThan1920px) return "20vw";
    if (isLargerThan1600px && !isLargerThan1920px) return "30vw";
    if (isLargerThan1200px && !isLargerThan1600px) return "40vw";
    if (isLargerThan960px && !isLargerThan1200px) return "45vw";
    if (isLargerThan768px && !isLargerThan960px) return "50vw";
    if (isLargerThan320px && !isLargerThan768px) return "75vw";
    return res;
  }

  return (
    <Flex justifyContent="center" flexWrap="wrap" alignItems="center" pb="3rem">
      <Box
        p={["1rem", "1rem", "1rem", "3rem"]}
        w={["100%", "100%", "100%", "50%"]}
      >
        <Center>
          <Avatar
            borderRadius="none"
            m="auto"
            boxSize="100%"
            css={{ width: res }}
            src={imgSrc}
            alt={"Mitarbeiter in der Praxis Heine"}
          />
        </Center>
      </Box>
      <Box
        p={["1rem", "1rem", "1rem", "3rem"]}
        w={["100%", "100%", "100%", "50%"]}
      >
        <Center>
          <Flex w={["75vw", "50vw", "66vw", "33vw"]}>{children}</Flex>
        </Center>
      </Box>
    </Flex>
  );
};
