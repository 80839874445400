import * as React from "react";
import { useStyleConfig } from "@chakra-ui/react";

import { SectionWrapper } from "../components/SectionWrapper";
import { PageType } from "../config/theme";

import { TeamOsteo } from "./TeamOsteo";

type Props = {
  pageType: PageType;
};

export const PraxisTeam: React.FC<Props> = ({ pageType }: Props) => {
  const itemStyles = useStyleConfig("ListItem", {});

  return (
    <SectionWrapper
      sectionName="praxisTeam"
      pageType={pageType}
      styles={{ ...itemStyles, background: "#ECECED" }}
    >
      <TeamOsteo />
    </SectionWrapper>
  );
};
