import "animate.css/animate.min.css";

import React from "react";
import { Box, SystemStyleObject } from "@chakra-ui/react";

import { PageType } from "../config/theme";

type Props = {
  pageType?: PageType;
  sectionName: string;
  children: React.ReactNode;
  styles?: SystemStyleObject;
};

export const SectionWrapper: React.FC<Props> = ({
  pageType,
  sectionName,
  children,
  styles,
}: Props) => {
  return (
    <Box
      as="section"
      maxWidth="100%"
      width="100%"
      minWidth="100%"
      css={{ label: `Section-${sectionName}-${pageType}` }}
      display="block"
      textAlign="center"
      pt="3.5rem"
      sx={styles}
      fontSize={["sm", "md", "lg", "xl"]}
    >
      {children}
    </Box>
  );
};
