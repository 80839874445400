import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { MetaInfo } from "../components/MetaInfo";
import { SectionDevider } from "../components/SectionDevider";
import { RoutesConfig } from "../config/routes.config";
import { PageType } from "../config/theme";

import { ContactInfo } from "./ContactInfo";
import { KinderOsteoInfos } from "./KinderOsteoInfos";
import { OsteoInfos } from "./OsteoInfos";
import { OsteoServices } from "./OsteoServices";
import { PraxisTeam } from "./PraxisTeam";
import { WelcomeSection } from "./WelcomeSection";

type Props = {
  pageType: PageType;
};

export const Home: React.FC<Props> = ({ pageType }: Props) => {
  const location = useLocation();

  const getMetaInfo = () => {
    switch (location.pathname) {
      case RoutesConfig.Home.path:
        return RoutesConfig.Home.metaInfo;
      case RoutesConfig.Impressum.path:
        return RoutesConfig.Impressum.metaInfo;
      case RoutesConfig.Datenschutz.path:
        return RoutesConfig.Datenschutz.metaInfo;
      default:
        return RoutesConfig.Home.metaInfo;
    }
  };

  return (
    <Box textAlign="center" pt={["0", "1rem", "1rem", "1rem"]}>
      <MetaInfo {...getMetaInfo()} />

      <SectionDevider linkToHash="welcome" />
      <WelcomeSection pageType={pageType} />

          <SectionDevider linkToHash="osteopathie" />
          <OsteoInfos pageType={pageType} />
   
   
          <SectionDevider linkToHash="praxisleistungen" />
          <OsteoServices pageType={pageType} />
  
          <SectionDevider linkToHash="kinderOsteopathie" />
          <KinderOsteoInfos pageType={pageType} />

  
      <SectionDevider linkToHash="team" />
      <PraxisTeam pageType={pageType} />
      <SectionDevider linkToHash="kontakt" />
      <ContactInfo pageType={pageType} />
    </Box>
  );
};
