import * as React from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Image,
  Link as ExternalLink,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";

import kinderosteo from "../../images/kinderosteopathie-logo.png";
// import logoVOD from "../../images/logo-vod-2021.png";
import logoVOD2 from "../../images/VODlogo.png";

export const TeamPeter: React.FC = () => {
  return (
    <Box textAlign="left">
      <Heading>Peter Heine</Heading>
      <br />
      <List spacing={4}>
        <ListItem display="flex">
          <ListIcon as={AddIcon} />
          <Text>
            2005-2008: Ausbildung zum Physiotherapeuten mit Abschluß an der{" "}
            <ExternalLink
              href="https://motima.de/"
              target="_blank"
              rel="noopener noreferrer"
              isExternal
              textDecoration="underline"
            >
              Motima Schule für Physiotherapie
            </ExternalLink>{" "}
            in Villingen-Schwenningen
          </Text>
        </ListItem>
        <ListItem display="flex">
          <ListIcon as={AddIcon} />
          <Text>2012: Eröffnung eigener Praxis in Karlsruhe-Wolfartsweier</Text>
        </ListItem>
        <ListItem display="flex">
          <ListIcon as={AddIcon} />
          <Text>
            Juni 2016: Abschluß der 5 jährigen Ausbildung zum Osteopathen am{" "}
            <ExternalLink
              href="https://www.ifaop.com/"
              target="_blank"
              rel="noopener noreferrer"
              textDecoration="underline"
            >
              Institut für angewandte Osteopathie IFAO
            </ExternalLink>
          </Text>
        </ListItem>
        <ListItem display="flex">
          <ListIcon as={AddIcon} />
          <Text>
            Mai 2017: Abschlußprüfung als Heilpraktiker am Landratsamt Karlsruhe
          </Text>
        </ListItem>
        <ListItem display="flex">
          <ListIcon as={AddIcon} />
          <Text>Oktober 2019: Zertifikat Kinderosteopathie</Text>
        </ListItem>
        <ListItem display="flex">
          <ListIcon as={AddIcon} />
          <Text>
            Mehrere Fortbildungen im Bereich Kinderosteopathie,
            Craniomandibuläre Dysfunktion (CMD), Diaphragmen und Zirkulation,
            Hormonsystem
          </Text>
        </ListItem>
        <ListItem display="flex">
          <Flex justify="space-evenly">
            <Box w="20%">
              <Image
                src={logoVOD2}
                alt={"Logo Verband der Osteopathen Deutschland e.V."}
              />
            </Box>
            <Box w="20%">
              <Image
                borderRadius="full"
                objectFit="cover"
                src={kinderosteo}
                alt="Kinderosteopathie in der Praxis Heine"
              />
            </Box>
          </Flex>
        </ListItem>
      </List>
    </Box>
  );
};
