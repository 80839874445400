import React from "react";
import { Heading } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
};

export const HeadingH2: React.FC<Props> = ({ children }: Props) => {
  return (
    <Heading as="h2" fontSize="1.3rem">
      {children}
    </Heading>
  );
};
