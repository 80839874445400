import * as React from "react";
import { Box } from "@chakra-ui/react";

import { Person } from "../components/Person";
import { TeamPeter } from "../components/team/TeamPeter";
import peter from "../images/peter.svg";

export const TeamOsteo: React.FC = () => {
  return (
    <Box>
      <Person imgSrc={peter}>
        <TeamPeter />
      </Person>
    </Box>
  );
};
