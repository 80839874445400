import React from "react";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link as ExternalLink,
  Text,
} from "@chakra-ui/react";

import { SectionWrapper } from "../components/SectionWrapper";
import { Slide } from "../components/Slide";
import { PageType } from "../config/theme";
import kinderosteo from "../images/kinderosteopathie.jpg";
import kinderosteologo from "../images/kinderosteopathie-logo.png";

type Props = {
  pageType: PageType;
};

const slide1 = (
  <Slide
    key="1"
    text1="Die Osteopathie ist für alle Altersklassen. Dies gilt selbstverständlich auch für Kinder, die 
    sich in einem Lebensabschnitt befinden, in dem sich innere und äußere Einflüsse so 
    entscheidend auswirken. In keiner anderen Phase der Entwicklung macht der Organismus 
    solche großen anatomischen, physiologischen und psychischen Veränderungen durch."
    text2="Aus Sicht der Osteopathie können bereits mit der Geburt Funktionsstörungen entstehen, die 
    erst Jahrzehnte später zu Symptomen führen oder bestimmte Krankheiten begünstigen. Der 
    Osteopath sucht nach der Ursache der Funktionsstörung mit dem Ziel, der betroffenen 
    Struktur ihre ursprüngliche Bewegung zurückzugeben."
  />
);

const slide2 = (
  <Slide
    key="2"
    text1="Ziel der osteopathischen Behandlung ist es, das Kind in seiner Entwicklung optimal zu 
    unterstützen und die Selbstheilungskräfte des kleinen Körpers zu mobilisieren und auch 
    prophylaktisch Fehlentwicklungen entgegen zu wirken."
    text2="Um Säuglinge und Kinder zu behandeln, ist es wichtig, die unterschiedlichen 
    Entwicklungsstufen des Kindes genau zu kennen und die normale sensorische, emotionale 
    und neurologische Entwicklung des Kindes und die spezielle Kinderpathologie zu kennen."
  />
);

const slide3 = (
  <Center
    key="3"
    p={["0 3rem", "0 3rem ", "3rem 8rem", "3rem 6rem"]}
    h={["100%", "100%", "100%", "35rem"]}
    css={{ hyphens: "auto" }}
  >
    <Box>
      <Text textAlign="justify">
        Mitglieder des Verbandes der Osteopathen, die eine entsprechend
        umfassende Fortbildung in dem Bereich der Behandlung von Kindern
        durchlaufen haben, sind mit dem Symbol auf der Osteopathenliste des VOD
        unter www.osteopathie.de/osteopathenliste gekennzeichnet.
      </Text>
      <Box>
        <Image
          m="auto"
          w="30%"
          h="30%"
          borderRadius="full"
          objectFit="cover"
          src={kinderosteologo}
          alt="Kinderosteopathie in der Praxis Heine"
        />
      </Box>
    </Box>
  </Center>
);

const slides = [slide1, slide2, slide3];

export const KinderOsteoInfos: React.FC<Props> = ({ pageType }: Props) => {
  return (
    <SectionWrapper sectionName="kinderosteoinfos" pageType={pageType}>
      <Box>
        <Heading as="h1" pb="0.5rem">
          Osteopathische Behandlung von Kindern
        </Heading>
      </Box>
      <Flex
        flexWrap={["wrap-reverse", "wrap-reverse", "wrap-reverse", "wrap"]}
        h="auto"
      >
        <Box w={["100%", "100%", "100%", "50%"]} /* h="auto" */>
          <Center>
            <Carousel
              autoPlay={false}
              animation="slide"
              navButtonsAlwaysVisible={true}
              timeout={800}
              navButtonsProps={{
                className: "",
                style: {
                  width: "2rem",
                  height: "2rem",
                },
              }}
            >
              {slides.map((slide) => slide)}
            </Carousel>
          </Center>
          <Text textAlign="left" p="3rem">
            (Quelle Bild & Text: VOD e.V.,&nbsp;
            <ExternalLink href="https://www.osteopathie.de/" isExternal>
              www.osteopathie.de
            </ExternalLink>
            )
          </Text>
        </Box>
        <Box w={["100%", "100%", "100%", "50%"]}>
          <Image
            m="auto"
            w={["100vw", "85vw", "85vw", "40vw"]}
            h="100%"
            p={["0", "2rem", "2rem", "2rem"]}
            objectFit="cover"
            src={kinderosteo}
            alt="Kinderosteopathie in der Praxis Heine"
          />
        </Box>
      </Flex>
    </SectionWrapper>
  );
};
