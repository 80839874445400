import React from "react";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link as ExternalLink,
  Text,
} from "@chakra-ui/react";

import { SectionWrapper } from "../components/SectionWrapper";
import { Slide } from "../components/Slide";
import { PageType } from "../config/theme";
import osteopathie from "../images/osteopathie.jpg";
type Props = {
  pageType: PageType;
};

const slide1 = (
  <Slide
    key="1"
    headline="Was ist Osteopathie"
    text1="Osteopathie ist eine eigenständige, ganzheitliche Form der Medizin, in
  der Diagnostik und Behandlung mit den Händen erfolgen. Osteopathie geht
  dabei den Ursachen von Beschwerden auf den Grund und behandelt den
  Menschen in seiner Gesamtheit."
    text2=" Vor über 140 Jahren entwickelte der amerikanische Arzt Andrew Taylor
  Still nach intensiver Forschung die Prinzipien der Osteopathie und
  begründete damit eine neue Medizin. Seitdem wird die Osteopathie stetig
  weiterentwickelt."
  />
);

const slide2 = (
  <Slide
    key="2"
    headline="Wirkung der Osteopathie"
    text1="Unser Organismus besteht aus unzähligen Strukturen, die alle direkt oder
  indirekt miteinander zusammenhängen. Eine wichtige Verbindung wird durch
  die sogenannten Faszien geschaffen. Das sind dünne Bindegewebshüllen,
  die jede Struktur umgeben und gemeinsam eine große Körperfaszie bilden.
  Meist finden die Faszien in der Schulmedizin kaum Beachtung, für die
  Osteopathie hingegen sind sie von großer Bedeutung. Faszien verbinden
  auch solche Strukturen, die funktionell nichts miteinander zu tun haben.
  Sie können daher aus Sicht der Osteopathie Veränderungen übertragen, was
  erklärt, warum die Beschwerden oft an anderer Stelle auftreten, als die
  Ursache zu finden ist."
  />
);

const slide3 = (
  <Slide
    key="3"
    headline="Osteopathie mobilisiert Selbstheilungskräfte"
    text1="Osteopathie baut auf den körpereigenen Selbstheilungs- und
Selbstregulationskräften auf. Krankheiten und Störungen entstehen oftmals
dadurch, dass der Körper die Fähigkeit zur Selbstregulierung verliert. An
diesem Punkt setzt die Osteopathie an und mobilisiert die
Selbstheilungskräfte des Körpers."
    text2="Jedes Körperteil, jedes Organ benötigt zum optimalen Funktionieren
ausreichende Bewegungsfreiheit. Ziel osteopathischer Behandlungen ist es,
Blockaden und Gewebespannung zu lösen und somit die Beweglichkeit
wiederherzustellen. Osteopathen forschen dabei immer nach den Ursachen von
Beschwerden und beschränken sich nicht auf die Behandlung einzelner
Symptome. Es werden also keine Krankheiten im eigentlichen Sinne
behandelt, sondern deren Auslöser, wie beispielsweise Bewegungsstörungen."
  />
);

const slide4 = (
  <Slide
    key="4"
    headline="Osteopathie wirkt auch begleitend"
    text1="Die Osteopathie findet in vielen medizinischen Bereichen Anwendung. Dabei
  kann die Osteopathie als erste und einzige medizinische Maßnahme völlig
  ausreichend sein."
    text2="Je nach Beschwerdebild kann sie aber auch begleitend, interdisziplinär zu
  anderen medizinischen Behandlungen eingesetzt werden. Letzteres ist vor
  allem dann sinnvoll, wenn es sich um schwere Pathologien, also
  schwerwiegende Erkrankungen, handelt."
  />
);

const slides = [slide1, slide2, slide3, slide4];

export const OsteoInfos: React.FC<Props> = ({ pageType }: Props) => {
  return (
    <SectionWrapper sectionName="osteoinfos" pageType={pageType}>
      <Box>
        <Heading as="h1" pb="0.5rem">
          Was ist Osteopathie
        </Heading>
      </Box>
      <Flex flexWrap="wrap" h="auto">
        <Box w={["100%", "100%", "100%", "50%"]}>
          <Image
            m="auto"
            //  maxHeight="30rem"
            w={["100vw", "85vw", "85vw", "40vw"]}
            h="100%"
            p={["0", "2rem", "2rem", "2rem"]}
            objectFit="cover"
            src={osteopathie}
            alt="Anwendung von Osteopathie in der Praxis Heine"
          />
        </Box>
        <Box w={["100%", "100%", "100%", "50%"]}>
          <Center p={["0", "2rem", "2rem", "2rem"]}>
            <Carousel
              autoPlay={false}
              animation="slide"
              navButtonsAlwaysVisible={true}
              timeout={800}
              navButtonsProps={{
                className: "",
                style: {
                  width: "2rem",
                  height: "2rem",
                },
              }}
            >
              {slides.map((slide) => slide)}
            </Carousel>
          </Center>
          <Text textAlign="left" p="3rem">
            (Quelle Bild & Text: VOD e.V.,&nbsp;
            <ExternalLink href="https://www.osteopathie.de/" isExternal>
              www.osteopathie.de
            </ExternalLink>
            )
          </Text>
        </Box>
      </Flex>
    </SectionWrapper>
  );
};
