import React from "react";
import { FaBars } from "react-icons/fa";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Link as ExternalLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useLocation, NavLink } from "react-router-dom";
import { Image, Text } from "@chakra-ui/react";

import { ScrollLink } from "../components/ScrollLink";
import logoOsteo from "../images/logo-osteo.png";

export const NavContactBox: React.FC = () => {
  const location = useLocation();
  const getMail = () => {
    return "info@osteopathie-heine.de";
  };

  const isHomePage =
    !location.pathname.includes("datenschutz") &&
    !location.pathname.includes("impressum");
  const getLogo = () => {
    return logoOsteo;
  };
  const CombinedMenu = () => {
    return (
      <Menu>
        <MenuButton as={Button} w="2rem">
          <FaBars />
        </MenuButton>
        <MenuList>
          {!isHomePage && (
            <NavLink to="/">
              <Text
                css={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Image
                  borderRadius="full"
                  mt="1rem"
                  w="auto"
                  h="5rem"
                  src={getLogo()}
                  bg="#ffffff"
                  alt={"Praxis für Osteopathie Heine"}
                />
              </Text>
            </NavLink>
          )}

          {isHomePage && (
            <>
              <MenuItem>
                <ScrollLink linkText="Welcome" linkData="welcome" />
              </MenuItem>
              <MenuItem>
                <ScrollLink
                  linkText="Über Osteopathie"
                  linkData="osteopathie"
                />
              </MenuItem>

              <MenuItem>
                <ScrollLink
                  linkText="Praxisleistungen"
                  linkData="praxisleistungen"
                />
              </MenuItem>

              <MenuItem>
                <ScrollLink
                  linkText="Kinderosteopathie"
                  linkData="kinderOsteopathie"
                />
              </MenuItem>

              <MenuItem>
                <ScrollLink linkText="Unser Team" linkData="team" />
              </MenuItem>
              <MenuItem>
                <ScrollLink linkText="Kontakt" linkData="kontakt" />
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
    );
  };

  return (
    <Flex justifyContent="space-around" w="100%" alignItems="center">
      <CombinedMenu />

      <ExternalLink
        padding="5px"
        target="_blank"
        aria-label="phone"
        rel="noopener noreferrer"
        href="tel:0721 483 963 8"
      >
        <PhoneIcon mr="5px" />
        0721 - 483 96 38
      </ExternalLink>

      <ExternalLink
        padding="5px"
        target="_blank"
        aria-label="mail"
        rel="noopener noreferrer"
        href="mailto:info@osteopathie-heine.de"
      >
        <EmailIcon mr="5px" />
        {getMail()}
      </ExternalLink>
    </Flex>
  );
};
