import React from "react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import { Button, useStyleConfig } from "@chakra-ui/react";

import { ScrollLink } from "./ScrollLink";

export const BackToTop: React.FC = () => {
  const backtoTopStyle = useStyleConfig("BackToTop", {});

  return (
    <ScrollLink
      linkData="welcome"
      linkText={
        <Button
          bg={backtoTopStyle.bg as string}
          color="white"
          size="lg"
          position="fixed"
          bottom="2rem"
          right={["1rem", "2rem"]}
          borderRadius="100%"
          opacity="0.85"
          w={["3rem", "4rem"]}
          h={["3rem", "4rem"]}
        >
          <ArrowUpIcon />
        </Button>
      }
    />
  );
};
