import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";

import fonts from "./config/FontFace";
import { RoutesConfig } from "./config/routes.config";
import { osteoTheme, PageType } from "./config/theme";
import { Base } from "./containers/Base";
import { NotFound } from "./containers/NotFound";

export const App: React.FC = () => {
  const location = useLocation();
  const [themeStyle, setThemeStyle] = useState<PageType>("osteopathie");

  useEffect(() => {
    setThemeStyle("osteopathie");
  }, [location.pathname]);

  const BaseStyled = () => <Base pageType={themeStyle} />;

  return (
    <ChakraProvider theme={osteoTheme}>
      <Global styles={fonts} />

      <Switch>
        <Route
          path={RoutesConfig.Home.path}
          component={BaseStyled}
          exact={RoutesConfig.Home.exact}
        />

        <Route path={RoutesConfig.Impressum.path} component={BaseStyled} />

        <Route path={RoutesConfig.Datenschutz.path} component={BaseStyled} />

        <Route component={NotFound} />
      </Switch>
    </ChakraProvider>
  );
};
