import * as React from "react";
import { Box, Text } from "@chakra-ui/react";

export const NotFound: React.FC = () => {
  return (
    <Box>
      <Text>Not found</Text>
    </Box>
  );
};
