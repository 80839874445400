import React from "react";
import { SystemStyleObject, useStyleConfig } from "@chakra-ui/react";

import { NavContactBox } from "../components/NavContactBox";
import { SectionWrapper } from "../components/SectionWrapper";
import { PageType } from "../config/theme";

type Props = {
  pageType: PageType;
};

export const ContactBar: React.FC<Props> = ({ pageType }: Props) => {
  const contactBarStyles = useStyleConfig("ContactBar", {});
  const componentStyles: SystemStyleObject = {
    position: "fixed",
    top: 0,
    zIndex: "5",
    padding: "0.5rem",
    display: ["block", "none", "none", "none"],
    opacity: 0.85,
  };
  const sectionStyles = { ...contactBarStyles, ...componentStyles };
  return (
    <SectionWrapper
      sectionName="ContactBar"
      pageType={pageType}
      styles={sectionStyles}
    >
      <NavContactBox />
    </SectionWrapper>
  );
};
