export default `
@font-face {
  font-family: 'Neo Sans Arabic Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Arabic Regular'), url('Neo Sans Arabic Regular.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Neo Sans Arabic Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Arabic Regular'), url('NeoSansArabic.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Neo Sans Arabic Light';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Arabic Light'), url('NeoSansArabicLight.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Neo Sans Arabic Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Arabic Medium'), url('NeoSansArabicMedium.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Neo Sans Arabic Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Arabic Bold'), url('NeoSansArabicBold.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Neo Sans Arabic Black';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Arabic Black'), url('NeoSansArabicBlack.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Neo Sans Arabic Ultra';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Arabic Ultra'), url('NeoSansArabicUltra.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Neo Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Sans Medium'), url('Neo_Sans_Medium.woff') format('woff');
  }
  `;
