import * as React from "react";
import Carousel from "react-material-ui-carousel";
import { Image, SystemStyleObject } from "@chakra-ui/react";

import { SectionWrapper } from "../components/SectionWrapper";
import { PageType } from "../config/theme";
import img1 from "../images/img1.jpg";
import img2 from "../images/img2.jpg";
import img3 from "../images/img3.jpg";
import logoOsteo from "../images/logo-osteo.png";

type Props = {
  pageType: PageType;
};

export const WelcomeSection: React.FC<Props> = ({ pageType }: Props) => {
  const componentStyles: SystemStyleObject = {
    paddingTop: ["3rem", "3rem", "6rem", "6rem"],
  };

  const getLogo = () => {
    return logoOsteo;
  };

  const sliderImages = [getLogo(), img1, img2, img3];

  return (
    <SectionWrapper
      sectionName="welcome"
      pageType={pageType}
      styles={componentStyles}
    >
      <Carousel
        autoPlay={false}
        animation="slide"
        navButtonsAlwaysVisible={true}
        timeout={800}
        navButtonsProps={{
          className: "",
          style: {
            width: "2rem",
            height: "2rem",
          },
        }}
      >
        {sliderImages.map((img, index) => {
          return (
            <Image
              key={index}
              m="auto"
              w={index !== 0 ? "100vw" : ""}
              h={["33vh", "40vh", "33rem", "33rem"]}
              objectFit="cover"
              src={img}
              alt="Foto aus der Praxis für Osteopathie und Physiotherapie Heine"
            />
          );
        })}
      </Carousel>
    </SectionWrapper>
  );
};
