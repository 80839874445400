import * as React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

import { HeadingH2 } from "../components/HeadingH2";
import { MetaInfo } from "../components/MetaInfo";
import { SectionWrapper } from "../components/SectionWrapper";
import { RoutesConfig } from "../config/routes.config";
import { PageType } from "../config/theme";

type Props = {
  pageType: PageType;
};

export const Impressum: React.FC<Props> = ({ pageType }: Props) => {
  return (
    <SectionWrapper
      sectionName="Impressum"
      pageType={pageType}
      styles={{ padding: "5rem", textAlign: "justify" }}
    >
      <MetaInfo {...RoutesConfig.Impressum.metaInfo} />

      <Heading>Impressum</Heading>
      <Box pt="2rem">
        <HeadingH2>Berufsbezeichnung</HeadingH2>
        <Text pt="0.75rem">Staatl. anerkannter Physiotherapeut</Text>
        <Text>
          Verliehen durch Regierungspräsidium Freiburg St.-Nr. 34188/23025
        </Text>
      </Box>
      <Box pt="2rem">
        <HeadingH2>Angaben gemäß § 5 TMG:</HeadingH2>
        <HeadingH2>Haftung für Inhalte</HeadingH2>
        <Text pt="0.75rem">
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </Text>
      </Box>
      <Box pt="2rem">
        <HeadingH2>Haftung für Links</HeadingH2>
        <Text pt="0.75rem">
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </Text>
      </Box>
      <Box pt="2rem">
        <HeadingH2>Urheberrecht</HeadingH2>
        <Text pt="0.75rem">
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </Text>
      </Box>
      {/*       <Box pt="2rem">
        <HeadingH2>Datenschutz</HeadingH2>
        <Text pt="0.75rem">
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass
          die Datenübertragung im Internet (z.B. bei der Kommunika-tion per
          E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
          Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von
          im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch
          Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung
          und Informations-materialien wird hiermit ausdrücklich widersprochen.
          Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
          Schritte im Falle der unverlangten Zusendung von Werbeinformationen,
          etwa durch Spam-Mails, vor.
        </Text>
        <Text pt="0.75rem">
          Quellen: Disclaimer von &nbsp;
          <Link to="http://www.e-recht24.de/muster-disclaimer.htm">
            eRecht24
          </Link>
        </Text>
      </Box> */}
    </SectionWrapper>
  );
};
