import React from "react";
import { Link as ReactScrollLink } from "react-scroll";
import { Text } from "@chakra-ui/react";

import { osteoTheme } from "../config/theme";

type Props = {
  linkText: string | React.ReactElement;
  linkData: string;
  children?: React.ReactNode;
};

export const ScrollLink: React.FC<Props> = ({
  linkText,
  linkData,
  children,
}: Props) => {
  const isText = typeof linkText === "string" ? true : false;

  function getAltText(): string {
    return isText ? `linkText` : linkData;
  }

  const getLink = () => {
    return `#${linkData}`;
  };

  const link = getLink();

  const getFontColor = () => {
    return osteoTheme.osteoColors.primary;
  };

  return (
    <ReactScrollLink
      href={link}
      to={link}
      spy={true}
      smooth={true}
      offset={50}
      duration={500}
      alt={getAltText()}
    >
      {!children ? (
        isText ? (
          <Text
            fontSize={["1rem", "1rem", "1.5rem", "1.5rem"]}
            color={getFontColor()}
            _hover={{
              textDecoration: "underline",
            }}
            p="0.5rem"
          >
            {linkText}
          </Text>
        ) : (
          linkText
        )
      ) : (
        children
      )}
    </ReactScrollLink>
  );
};
