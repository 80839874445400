import * as React from "react";
import {
  Box,
  // Center,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

import { HeadingH2 } from "../components/HeadingH2";
import { MetaInfo } from "../components/MetaInfo";
import { SectionWrapper } from "../components/SectionWrapper";
import { RoutesConfig } from "../config/routes.config";
import { PageType } from "../config/theme";

type Props = {
  pageType: PageType;
};

type ListItemProps = {
  heading: string;
  text1: React.ReactNode;
  text2?: React.ReactNode;
  text3?: React.ReactNode;
};

export const Datenschutz: React.FC<Props> = ({ pageType }: Props) => {
  const CustomListItem = ({ heading, text1, text2, text3 }: ListItemProps) => {
    return (
      <ListItem pt="1rem">
        <Box>
          <HeadingH2>{heading}</HeadingH2>
          <Box pt="0.5rem">
            <Text textAlign="left">{text1}</Text>
          </Box>
          {text2 && (
            <Box pt="0.75rem">
              <Text textAlign="left">{text2}</Text>
            </Box>
          )}
          {text3 && (
            <Box pt="0.75rem">
              <Text textAlign="left">{text3}</Text>
            </Box>
          )}
        </Box>
      </ListItem>
    );
  };

  const NumberList = ({ items }: { items: Array<ListItemProps> }) => {
    return (
      <Box>
        <OrderedList styleType="decimal">
          {items.map((item) => (
            <CustomListItem
              key={item.heading}
              heading={item.heading}
              text1={item.text1}
              text2={item.text2}
            />
          ))}
        </OrderedList>
      </Box>
    );
  };

  const Letterlist = ({ items }: { items: Array<ListItemProps> }) => {
    return (
      <Box>
        <OrderedList styleType="lower-alpha">
          {items.map((item) => (
            <CustomListItem
              key={item.heading}
              heading={item.heading}
              text1={item.text1}
              text2={item.text2}
            />
          ))}
        </OrderedList>
      </Box>
    );
  };

  const sub3: Array<ListItemProps> = [
    {
      heading: "Verarbeitete Daten",
      text1: (
        <UnorderedList>
          <ListItem>
            <Text>Browsertyp und Browserversion</Text>
          </ListItem>
          <ListItem>
            <Text>verwendetes Betriebssystem</Text>
          </ListItem>
          <ListItem>
            <Text>
              Webseite, von denen das System des Nutzers auf unsere
              Internetseite gelangt
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Webseite, die vom System des Nutzers über unsere Webseite
              aufgerufen werden
            </Text>
          </ListItem>
          <ListItem>
            <Text>Hostname des zugreifenden Rechners</Text>
          </ListItem>
          <ListItem>
            <Text>Uhrzeit der Serveranfrage</Text>
          </ListItem>
          <ListItem>
            <Text>IP-Adresse</Text>
          </ListItem>
        </UnorderedList>
      ),
      text2: (
        <Text>
          {/*   Option 1: Die Logfiles enthalten Daten, die eine Zuordnung zu einem
      bestimmten Nutzer ermöglichen. 
          Die vorgenannten Daten werden in den sogenannten Logfiles unseres
          Systems gespeichert. Eine Verarbeitung dieser Daten zusammen mit
          anderen personenbezogenen Daten des Nutzers findet nicht statt.
          {/*      Option 2: Die Logfiles enthalten keine IP-Adressen
      oder sonstige Daten, die eine Zuordnung zu einem Nutzer der Webseite
      ermöglichen.  */}
          Die vorgenannten Daten werden in den sogenannten Logfiles unseres
          Systems gespeichert. Hiervon ausgenommen sind die sogenannten IP
          Adressen oder andere Daten des Nutzers, die die Zuordnung der Daten zu
          dem Nutzer ermöglichen. Eine Speicherung der Daten zusammen mit
          anderen personenbezogenen Daten erfolgt nicht.
        </Text>
      ),
    },
    {
      heading: "Zwecke der Verarbeitung von Daten",
      text1: (
        <Text>
          Die vorübergehende Speicherung der Daten ist notwendig, um Ihnen die
          Inanspruchnahme der Webseite zu ermöglichen. Die Speicherung der Daten
          in Logfiles erfolgt, um die Funktionsfähigkeit der Webseite
          sicherzustellen und zur Optimierung der Webseite und Sicherstellung
          der Sicherheit unseres Systems.
        </Text>
      ),
    },
    {
      heading: "Rechtsgrundlage",
      text1: (
        <Text>
          Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
          DSGVO. Die oben genannten Zwecke stellen auch unsere berechtigten
          Interessen im Sinne des Art. 6 Abs. 1 lit. f DSGVO an der
          Datenverarbeitung dar.
        </Text>
      ),
    },
    {
      heading: "Weitergabe an Dritte",
      text1: (
        <Text>
          Die personenbezogenen Daten, die auf dieser Webseite verarbeitet
          werden, werden nicht an Dritte weitergegeben.
          {/* Ggf. ändern, wenn Daten weitergegeben werden */}
        </Text>
      ),
    },
    {
      heading: "Datenlöschung und Speicherdauer",
      text1: (
        <Text>
          Die Speicherung der Daten in Logfiles erfolgt für maximal 7 Tage. Eine
          darüber hinausgehende Speicherung ist möglich. In diesem Fall werden
          die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine
          Zuordnung des aufrufenden Clients nicht mehr möglich ist.
          {/* Die personenbezogenen Daten werden gelöscht, sobald der Zweck der
          Speicherung entfällt, dh. mit Beendigung des Besuchs der Webseite. */}
          {/* Falls eine Speicherung von Daten in Logfiles erfolgt: 
Die Speicherung der Daten in Logfiles erfolgt für maximal 7 Tage. Eine darüber hinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist. 
*/}
        </Text>
      ),
    },
    {
      heading:
        "Keine gesetzliche oder vertragliche Pflicht zur Datenverarbeitung",
      text1: (
        <Text>
          Die Datenverarbeitung ist zwar nicht gesetzlich oder vertraglich
          vorgeschrieben, sie ist aber erforderlich, um Ihnen entsprechend dem
          oben genannten Zweck diese Webseite zur Verfügung zu stellen.
        </Text>
      ),
    },
  ];

  const sub6: Array<ListItemProps> = [
    {
      heading: "Widerruf der Einwilligung zur Datenverarbeitung",
      text1: (
        <Text>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </Text>
      ),
    },
    {
      heading: "Beschwerderecht bei der zuständigen Aufsichtsbehörde",
      text1: (
        <Text>
          Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
          Aufsichtsbehörde in datenschutzrechtlichen Fragen ist grundsätzlich
          der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
          Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
          sowie deren Kontaktdaten können folgendem Link entnommen werden:{" "}
          <br />
          <Link to="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </Link>
        </Text>
      ),
    },
    {
      heading:
        "Auskunft, Sperrung, Löschung, Berichtigung, Einschränkung der Datenverarbeitung, Widerspruchsrecht",
      text1: (
        <Text>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ein Recht auf Berichtigung, Sperrung oder
          Löschung dieser Daten sowie der Einschränkung der Verarbeitung sowie
          ein Recht auf Widerspruch gegen die Datenverarbeitung. Hierzu sowie zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        </Text>
      ),
    },
    {
      heading: "Recht auf Datenübertragbarkeit",
      text1: (
        <Text>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </Text>
      ),
    },
  ];

  const items: Array<ListItemProps> = [
    {
      heading: "Allgemeines",
      text1: (
        <Text>
          Die folgenden Hinweise geben einen Überblick darüber, was mit Ihren
          personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können.
        </Text>
      ),
    },
    {
      heading: "Hinweis zur verantwortlichen Stelle",
      text1: (
        <Text>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
          <br />
          Peter Heine
          <br />
          Walter-Bothe-Str. 4<br />
          76228 Karlsruhe
        </Text>
      ),
      text2: (
        <Text>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </Text>
      ),
    },
    {
      heading: "Datenverarbeitung auf unserer Website",
      text1: <Letterlist items={sub3} />,
    },
    {
      heading: "Cookies",
      text1: (
        <Text>
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
          dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer
          zu machen. Cookies sind kleine Textdateien, die auf dem Rechner des
          Nutzers abgelegt werden und die der Browser des Nutzers speichert.
          {/* Falls eine Verwendung technisch notwendiger Cookies erfolgt: 
Wir nutzen Cookies, um unsere Webseite nutzerfreundlicher zu gestalten. Einige Elemente unserer Webseite machen es erforderlich, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. 
In den Cookies werden dabei folgende Daten gespeichert und übermittelt: 
Es folgt eine Auflistung der gespeicherten Daten: Dies könnten sein:
-	Spracheinstellungen
-	Log-in-Informationen */}
          {/* Falls zudem eine Verwendung technisch nicht notwendiger Cookies
  erfolgt: Wir ermöglichen darüber hinaus Cookies, die eine Analyse des
  Surfverhaltens der Nutzer ermöglichen. Auf diese Weise können folgende
  Daten übermittelt werden: Es folgt eine Auflistung der tatsächlich
  übermittelten Daten: - Eingegebene Suchbegriffe - Häufigkeit von
  Seitenaufrufen - Inanspruchnahme von Webseitenfunktionen Die auf diese
  Weise erhobenen Daten der Nutzer werden durch technische Vorkehrungen
  pseudonymisiert. Dies bedeutet, dass eine Zuordnung der Daten zum
  jeweiligen Nutzer nicht mehr möglich ist. Die Daten werden nicht mit
  sonstigen personenbezogenen Daten gespeichert. Beim Aufruf unserer
  Webseite werden die Nutzer durch einen Infobanner über die Verwendung
  von Cookies zu Analysezwecken informiert und um Zustimmung zu dieser
  Datenverarbeitung gebeten. */}
        </Text>
      ),
      text2: (
        <>
          <Box pt="2rem">
            <HeadingH2>Zweck der Datenverarbeitung</HeadingH2>
            <Text pt="0.75rem">
              Zweck der Verwendung der Cookies ist die Vereinfachung der
              Webseitennutzung. Für folgende Funktionen der Webseite ist eine
              Wiedererkennung der Besucher durch den Einsatz von Cookies
              erforderlich. Die erhobenen Nutzerdaten werden nicht zur
              Erstellung von Nutzerprofilen verwendet.
              {/* Falls technisch nicht notwendige Cookies verwendet werden:
Die Verwendung der Analyse Cookies erfolgt zu dem Zweck, die Qualität der Webseite zu verbessern. Wir erfahren die Nutzung der Webseite und können die Angebote stetig optimieren. 
Der genaue Einsatz der Analysecookies sollte hier im Detail beschrieben sein. 
 */}
            </Text>
          </Box>
          <Box pt="2rem">
            <HeadingH2>Rechtsgrundlage</HeadingH2>
            <Text pt="0.75rem">
              Die Rechtsgrundlage für den Einsatz technisch notwendiger Cookies
              ist Art. 6 Abs. 1 lit. f. DSGVO. Der oben genannte
              Verwendungszweck der Datenverarbeitung stellt auch unser
              berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
              dar.
            </Text>
            <Text pt="0.75rem">
              Die Rechtsgrundlage für den Einsatz technisch notwendiger Cookies
              ist Art. 6 Abs. 1 lit. f. DSGVO. Der oben genannte
              Verwendungszweck der Datenverarbeitung stellt auch unser
              berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
              dar.
            </Text>
          </Box>
          <Box pt="2rem">
            <HeadingH2>
              Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
            </HeadingH2>
            <Text pt="0.75rem">
              Cookies werden auf dem Computer des Nutzes der Webseite
              gespeichert und Daten von dort an unser System übermittelt. Der
              Nutzer hat die Kontrolle über die Verwendung von Cookies. In den
              Einstellungen des Internetbrowsers kann die Übertragung von
              Cookies deaktiviert oder eingeschränkt werden. Gespeicherte
              Cookies können gelöscht werden. Werden Cookies gelöscht, können
              ggf. Teile der Webseite nicht mehr uneingeschränkt genutzt werden.
            </Text>
          </Box>
        </>
      ),
    },
    /*     {
      // TODO: löschen
      heading: "Kontaktformular",
      text1: (
        <Text>
          Für eine Kontaktaufnahme können Sie das auf meiner Website
          bereitgestellte Kontaktformular nutzen. Die Angabe einer gültigen
          E-Mail-Adresse ist erforderlich, um Ihre Anfrage beantworten zu
          können. Wenn Sie uns eine Nachricht über das Kontaktformular zukommen
          lassen, speichern wir die mitgeteilten Daten (z.B. Ihre
          E-Mail-Adresse, ggf. Ihren Namen und Ihre Telefonnummer), um Kontakt
          mit Ihnen aufzunehmen. Ist eine Speicherung nicht mehr erforderlich,
          löschen wir die erhobenen personenbezogenen Daten. Rechtsgrundlage für
          diese Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. b DSGVO, Anbahnung
          eines Vertragsverhältnisses.
        </Text>
      ),
    }, */
    { heading: "Rechte des Betroffenen", text1: <Letterlist items={sub6} /> },
    {
      heading: "Automatisierte Entscheidungsfindung",
      text1: (
        <Text>
          Ein Verfahren zur automatisierten Entscheidungsfindung kommt nicht zum
          Einsatz.
        </Text>
      ),
    },
    {
      heading: "SSL- bzw. TLS-Verschlüsselung",
      text1: (
        <Text>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von “http://” auf “https://”
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die
          SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie
          an uns übermitteln, nicht von Dritten mitgelesen werden.
        </Text>
      ),
    },
    {
      heading: "Widerspruch gegen Werbe-Mails",
      text1: (
        <Text>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </Text>
      ),
    },
    {
      heading: "Datenschutzbeauftragter",
      text1: (
        <Text>
          Wir haben einen Datenschutzbeauftragten bestellt. Geben Sie den vollen
          Namen bzw. die vollständige Unternehmensbezeichung sowie die
          vollständige Anschrift an.
          <br />
          Telefon: 0721 - 483 96 38
          <br />
          Datenschutzbeauftragter E-Mail: info@osteopathie-heine.de
          {/*   Oder: Zur
          Benennung eines Datenschutzbeauftragten sind wir nicht verpflichtet. */}
        </Text>
      ),
    },
  ];

  return (
    <SectionWrapper
      sectionName="Datenschutz"
      pageType={pageType}
      styles={{ padding: "5rem", textAlign: "justify" }}
    >
      <MetaInfo {...RoutesConfig.Datenschutz.metaInfo} />

      <Heading>Datenschutz</Heading>

      <NumberList items={items} />

      <Box pt="2rem">
        <Text pt="0.75rem">Stand: Januar 2021</Text>
      </Box>
    </SectionWrapper>
  );
};
