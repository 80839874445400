import { extendTheme } from "@chakra-ui/react";

const osteoColors = {
  primary: "#6a8b63",
};

const physioColors = {
  primary: "#952321",
};

const praxisFonts = {
  styles: {
    global: {
      body: {
        fontFamily: "Duru Sans",
        // fontWeight: "bold",
      },
    },
  },
  fonts: {
    body: "Neo Sans Arabic Regular, sans-serif",
    Heading: "Neo Sans Arabic Regular, sans-serif",
    mono: "Neo Sans Arabic Regular, monospace",
    Text: "Neo Sans Arabic Regular, sans-serif",
  },
};

const osteoComponents = {
  ContactBar: {
    baseStyle: {
      bg: "#fff",
      color: osteoColors.primary,
      opacity: "0.95",
    },
  },
  NavBar: {
    baseStyle: {
      bg: "#fff",
      color: osteoColors.primary,
    },
  },
  Heading: {
    baseStyle: {
      color: osteoColors.primary,
      fontFamily: praxisFonts.fonts.Text,
    },
  },
  HeadingH2: {
    color: osteoColors.primary,
    baseStyle: {
      color: osteoColors.primary,
    },
  },
  Text: {
    baseStyle: {
      fontFamily: praxisFonts.fonts.Text,
      color: "#707173",
      fontSize: ["0.8rem", "1rem", "1.1rem", "1.1rem"],
    },
  },
  ListItem: {
    baseStyle: {
      color: osteoColors.primary,
    },
  },
  Link: {
    baseStyle: {
      color: osteoColors.primary,
    },
  },
  AddIcon: {
    baseStyle: {
      color: osteoColors.primary,
    },
  },
  Footer: {
    baseStyle: {
      bg: osteoColors.primary,
      color: "#fff",
      opacity: "0.95",
    },
  },
  MenuItem: {
    baseStyle: {
      bg: osteoColors.primary,
      color: "#fff",
    },
  },
  BackToTop: {
    baseStyle: {
      bg: osteoColors.primary,
      color: "#fff",
      opacity: "0.85",
    },
  },
  MenuButton: {
    baseStyle: {
      color: osteoColors.primary,
      bg: "#fff",
    },
  },
};

const physioComponents = {
  ContactBar: {
    baseStyle: {
      bg: "#fff",
      color: physioColors.primary,
      opacity: "0.95",
    },
  },
  NavBar: {
    baseStyle: {
      bg: "#fff",
    },
  },
  Heading: {
    baseStyle: {
      color: physioColors.primary,
      fontFamily: praxisFonts.fonts.Text,
    },
  },
  HeadingH2: {
    color: physioColors.primary,
    baseStyle: {
      color: physioColors.primary,
    },
  },
  Text: {
    baseStyle: {
      fontFamily: praxisFonts.fonts.Text,
      color: "#707173",
      fontSize: ["0.8rem", "1rem", "1.1rem", "1.1rem"],
    },
  },
  ListItem: {
    baseStyle: {
      color: physioColors.primary,
    },
  },
  ListIcon: {
    baseStyle: {
      color: physioColors.primary,
    },
  },
  Link: {
    baseStyle: {
      color: physioColors.primary,
    },
  },
  AddIcon: {
    baseStyle: {
      color: physioColors.primary,
    },
  },
  Footer: {
    baseStyle: {
      bg: physioColors.primary,
      color: "#fff",
      opacity: "0.95",
    },
  },
  MenuItem: {
    baseStyle: {
      bg: physioColors.primary,
      color: "#fff",
    },
  },
  BackToTop: {
    baseStyle: {
      bg: physioColors.primary,
      color: "#fff",
      opacity: "0.85",
    },
  },
  MenuButton: {
    baseStyle: {
      color: physioColors.primary,
      bg: "#fff",
    },
  },
};

export const osteoTheme = extendTheme({
  praxisFonts,
  osteoColors,
  components: osteoComponents,
});

export const physioTheme = extendTheme({
  praxisFonts,
  physioColors,
  components: physioComponents,
});

export type PageType = "osteopathie" | "physiotherapie" | "neutral";
