import * as React from "react";
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";

import { HeadingH2 } from "../components/HeadingH2";
import { SectionWrapper } from "../components/SectionWrapper";
import { PageType } from "../config/theme";

type Props = {
  pageType: PageType;
};

export const OsteoServices: React.FC<Props> = ({ pageType }: Props) => {
  return (
    <SectionWrapper
      sectionName="osteoServices"
      pageType={pageType}
      styles={{ background: "#D9DADB" }}
    >
      <Flex justifyContent="center" flexWrap="wrap" pb="3rem">
        <Box
          p={["2rem", "2rem", "3rem", "3rem"]}
          w={["100%", "100%", "50%", "50%"]}
          textAlign="justify"
        >
          <Heading textAlign="center">Die Behandlung</Heading>
          <Text pt={["0.33rem", "1rem", "1rem", "1rem"]}>
            Nach ausführlicher Anamnese im Vorgespräch erfolgen Diagnose und
            Behandlung mit den Händen. Osteopathen ertasten bei der Untersuchung
            durch die sogenannte Palpation das menschliche Gewebe Schicht für
            Schicht und erspüren dadurch sowohl tieferliegende Strukturen wie
            Muskeln, Faszien, Knochen, als auch Nerven, Blutgefäße und die
            inneren Organe. So werden Bewegungseinschränkungen und Spannungen
            diagnostiziert. Die Behandlungsdauer beträgt in der Regel ca. 50
            Minuten. Der genaue Verlauf der Behandlung hängt jedoch vom
            Einzelfall ab. Es ist dabei nicht ungewöhnlich, dass die Reaktion
            auf eine osteopathische Behandlung erst einige Zeit später einsetzt.
            Um bei bestimmten Krankheitsbildern Erfolge erzielen zu können, ist
            mit mehreren Behandlungen zu rechnen.
          </Text>
        </Box>
        <Box
          p={["2rem", "2rem", "3rem", "3rem"]}
          textAlign="justify"
          w={["100%", "100%", "50%", "50%"]}
        >
          <Box>
            <Heading textAlign="center">Kosten</Heading>
            <Text pt={["0.33rem", "1rem", "1rem", "1rem"]}>
              Die Leistungen werden nach der Gebührenordnung für Heilpraktiker
              (GebüH) berechnet. Für eine Sitzung mit ausführlicher Anamnese,
              Untersuchung und Behandlung mit unterschiedlichen osteopathischen
              Techniken liegen die Kosten bei ca. 95€.
            </Text>
          </Box>
          <Box pt="2.2rem">
            <HeadingH2>
              Kostenerstattung durch gesetzliche Krankenkassen
            </HeadingH2>
            <Text pt={["0.33rem", "1rem", "1rem", "1rem"]}>
              Mittlerweile erstatten eine Vielzahl der gesetzlichen
              Krankenkassen die Kosten für Osteopathie anteilig. Zu welchen
              Bedingungen finden Sie auf folgender{" "}
              <Link href="https://www.osteopathie.de/krankenkassenliste">
                Liste
              </Link>
              . Wenden Sie sich direkt an Ihre Krankenkasse und informieren sich
              über deren Vertragsbedingungen.
            </Text>
          </Box>
        </Box>
      </Flex>
    </SectionWrapper>
  );
};
