import * as React from "react";
import { Element } from "react-scroll";
import { Box } from "@chakra-ui/react";

type Props = {
  linkToHash: string;
};

export const SectionDevider: React.FC<Props> = ({ linkToHash }: Props) => {
  return (
    <Box as="span" id={linkToHash} h={["0"]}>
      <Element name={`#${linkToHash}`} />
    </Box>
  );
};
