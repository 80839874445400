import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { BackToTop } from "../components/BackToTop";
import { PageType } from "../config/theme";

import { ContactBar } from "./ContactBar";
import { Datenschutz } from "./Datenschutz";
import { Footer } from "./Footer";
import { Home } from "./Home";
import { Impressum } from "./Impressum";
import { NavBar } from "./NavBar";
type Props = {
  pageType: PageType;
};

export const Base: React.FC<Props> = ({ pageType }: Props) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Box css={{ label: `Base-${pageType}`, maxWidth: "100%", width: "100%" }}>
      <ContactBar pageType={pageType} />
      <NavBar pageType={pageType} />

      {!pathname.includes("impressum") && !pathname.includes("datenschutz") && (
        <Home pageType={pageType} />
      )}

      {pathname.includes("impressum") && <Impressum pageType={pageType} />}
      {pathname.includes("datenschutz") && <Datenschutz pageType={pageType} />}
      <Footer pageType={pageType}></Footer>
      <BackToTop />
    </Box>
  );
};
