import * as React from "react";
import {
  NavLink as RouterDomLink,
} from "react-router-dom";
import {
  Box,
  Flex,
  SystemStyleObject,
  Text,
  useStyleConfig,
} from "@chakra-ui/react";

import { SectionWrapper } from "../components/SectionWrapper";
import { RoutesConfig } from "../config/routes.config";
import { PageType } from "../config/theme";

type Props = {
  pageType: PageType;
};

export const Footer: React.FC<Props> = ({ pageType }: Props) => {
  const themeStyles = useStyleConfig("Footer", {});
  const componentStyles: SystemStyleObject = {
    zIndex: "5",
    padding: "1rem 0",
    bg: themeStyles.bg,
  };
  const styles = { ...themeStyles, ...componentStyles };

  const getPathImpressum = () => {
  return RoutesConfig.Impressum.path;    
  };
  const getPathDatenschutz = () => {
    
        return RoutesConfig.Datenschutz.path;
    
  };

  return (
    <SectionWrapper sectionName="Footer" pageType={pageType} styles={styles}>
      <Flex justify="space-around">
        <Box w="25%"></Box>
        <RouterDomLink to={getPathImpressum()}>
          <Text css={{ color: pageType === "neutral" ? "#707173" : "inherit" }}>
            Impressum
          </Text>
        </RouterDomLink>
        <RouterDomLink to={getPathDatenschutz()}>
          <Text css={{ color: pageType === "neutral" ? "#707173" : "inherit" }}>
            Datenschutz
          </Text>
        </RouterDomLink>
        <Box w="25%"></Box>
      </Flex>
    </SectionWrapper>
  );
};
