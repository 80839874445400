import * as React from "react";
import { Box, Center, Text } from "@chakra-ui/react";

import { HeadingH2 } from "../components/HeadingH2";

type Props = {
  headline?: string;
  text1?: string;
  text2?: string;
};

export const Slide: React.FC<Props> = ({ headline, text1, text2 }: Props) => {
  return (
    <Center
      p={["0 3rem", "0 3rem ", "3rem 8rem", "3rem 6rem"]}
      h={["100%", "100%", "100%", "35rem"]}
      css={{ hyphens: "auto" }}
    >
      <Box>
        {headline && <HeadingH2>{headline}</HeadingH2>}
        <Box pt="0.5rem">
          {text1 && <Text textAlign="justify">{text1}</Text>}
          {text2 && (
            <Text textAlign="justify" pt="0.5rem">
              {text2}
            </Text>
          )}
        </Box>
      </Box>
    </Center>
  );
};
