import * as React from "react";
import {
  AttachmentIcon,
  EmailIcon,
  ExternalLinkIcon,
  PhoneIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Link as ExternalLink,
  // Center,
  Text,
} from "@chakra-ui/react";

import { SectionWrapper } from "../components/SectionWrapper";
import { PageType } from "../config/theme";
type Props = {
  pageType: PageType;
};
export const ContactInfo: React.FC<Props> = ({ pageType }: Props) => {
  const getHomepage = () => {
    return "https://www.osteopathie-heine.de";
  };

  const getMail = () => {
    return "info@osteopathie-heine.de";
  };

  return (
    <SectionWrapper
      sectionName="ContactInfo"
      pageType={pageType}
      styles={{ paddingTop: "1rem" }}
    >
      <Flex w="100%" justifyContent="center" flexWrap="wrap">
        <Box p="0rem 2rem 2rem 2rem">
          <Heading>Adresse</Heading>
          <Text pt={["0.33rem", "1rem", "1rem", "1rem"]}>
            Praxis Heine und Team
            <br />
            Osteopathie
            <br />
            Walther-Bothe-Straße 4 (Zündhütle)
            <br />
            76228 Karlsruhe-Wolfartsweier
          </Text>
        </Box>

        {/* Todo: Weiche schreiben für mail und web */}

        <Box p="0rem 2rem 2rem 2rem">
          <Heading>Kontakt</Heading>
          <Flex
            justifyContent="center"
            pt={["0.33rem", "1rem", "1rem", "1rem"]}
          >
            <Text>
              <ExternalLink
                target="_blank"
                aria-label="phone"
                rel="noopener noreferrer"
                href="tel:0721 483 963 8"
                isExternal
              >
                <PhoneIcon mr="5px" />
                Telefon: 0721 - 483 96 38
              </ExternalLink>
            </Text>
          </Flex>
          <Flex justifyContent="center">
            <Text>
              <AttachmentIcon mr="5px" />
              Telefax: 0721 474 885
            </Text>
          </Flex>
          <Flex justifyContent="center">
            <Text>
              <ExternalLink
                target="_blank"
                aria-label="mail"
                rel="noopener noreferrer"
                href={`mailto:${getMail()}`}
                isExternal
              >
                <EmailIcon mr="5px" />
                {getMail()}
              </ExternalLink>
            </Text>
          </Flex>
          <Flex justifyContent="center">
            <Text>
              <ExternalLink
                target="_blank"
                aria-label="mail"
                rel="noopener noreferrer"
                href={`${getHomepage()}`}
                isExternal
              >
                <ExternalLinkIcon mr="5px" />
                Web: {getHomepage()}
              </ExternalLink>
            </Text>
          </Flex>
        </Box>

        <Box p="0 2rem 2rem 2rem">
          <Heading>Öffnungszeiten</Heading>
          <Text pt={["0.33rem", "1rem", "1rem", "1rem"]}>
            Montag - Freitag:
            <br />
            08:00 - 19:00 Uhr
          </Text>
        </Box>
      </Flex>
    </SectionWrapper>
  );
};
